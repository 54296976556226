@import url(https://db.onlinewebfonts.com/c/8d4288d9428bdc9c0e1888766a5c6758?family=Omnes);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  font-family: "Omnes";
}

@font-face {
  font-family: "Omnes";
  src: url("https://db.onlinewebfonts.com/t/8d4288d9428bdc9c0e1888766a5c6758.eot");
  src: url("https://db.onlinewebfonts.com/t/8d4288d9428bdc9c0e1888766a5c6758.eot?#iefix") format("embedded-opentype"), url("https://db.onlinewebfonts.com/t/8d4288d9428bdc9c0e1888766a5c6758.woff2") format("woff2"), url("https://db.onlinewebfonts.com/t/8d4288d9428bdc9c0e1888766a5c6758.woff") format("woff"), url("https://db.onlinewebfonts.com/t/8d4288d9428bdc9c0e1888766a5c6758.ttf") format("truetype"), url("https://db.onlinewebfonts.com/t/8d4288d9428bdc9c0e1888766a5c6758.svg#Omnes") format("svg");
}

.page-loader {
  width: 100%;
  height: 100vh;
  background: #272727;
  z-index: 100000000000000000;
  position: fixed;
}
.page-loader .txt {
  color: #ffffff;
  text-align: center;
  top: 40%;
  position: relative;
  text-transform: uppercase;
  letter-spacing: 0.3rem;
  font-weight: bold;
  line-height: 1.5;
}
.spinner {
  position: relative;
  top: 35%;
  width: 80px;
  height: 80px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 100%;
  -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
  animation: sk-scaleout 1.0s infinite ease-in-out;
}
@-webkit-keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1.0);
    opacity: 0;
  }
}
@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
    opacity: 0;
  }
}


.logo2:hover{
  cursor: pointer;
}

.btnfos-0-3:hover {
  background-color: #3b3b3b;
}

.play-video {
  background-color: #1ee03e;
}

.videoBg {
  top: 0;
  left: 0px;
  width: 90%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.socialCreator2 {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  margin-bottom: 17px;
}

.socialCreator2 img:hover {

  cursor: pointer;

}

.socialCreator {
  display: flex;
  margin-right: auto;
}

.crossMint {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.icons:hover {

  cursor: pointer;
}

@media screen and (max-width: 500px) {
  .vidCont5 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1%;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border: 2px solid black;
  }
  .vidCont6 {
    display: none;
  }

  .vidCont1 {
    display: none;
  }

  .vidCont2 {
    display: none;
  }

  .vidCont3 {
    display: none;
  }

  .vidCont4 {
    display: none;
  }
  .socialCreator2 img {
    width: 22px;
    height: 22px;
    margin-left: 10%;
    margin-right: 10%;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .icons2 {
    display: flex;
    justify-content: center;
  }

  .logo3 img {
    width: 50px;
    height: 50px;
  }

  .miniBarMain {
    padding-bottom: 10px;
    padding-top: 10px;
    width:  100vw;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;    padding-left: 5%;
    padding-right: 5%;
    background-color: rgb(255, 255, 255);
    display: flex;
    justify-content: space-between;
  }

  .miniBar {
    display: flex;
    justify-content: space-between;
  }

  .navbarMainImgSet {
    padding-right: 30px;
     position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000000000000000000;
    align-items: center;
    display: flex;
  }

  .navbar {
    width: 30px;
    justify-content: flex-end;
    cursor: pointer;    
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    display: block;
    z-index: 1000000000000000000;
    align-items: center;

  }

  #fontSize2 {
    font-size: 15px;
    color: white;
    text-align: left;
  }

  #fontSize2:hover {
    color: #818181;
  }

  .littleNav div {
    text-align: left;
    padding: 30px;
    cursor: pointer;
    border-top: rgba(255, 255, 255, 0.233) 1px solid;
  }

  .littleNav {
    background-color: #1a1a1ad8;
    width: 100%;
    height: -webkit-max-content;
    height: max-content;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000000000;
    flex-flow: column wrap;
    display: flex;
    border-bottom: 5px rgba(49, 49, 49, 0.603) solid;
  }

  .hidden {
    display: none;
  }

  .close {
    width: 35px;
    height: 35px;
    justify-content: flex-end;
    margin-left: auto;
    cursor: pointer;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
  }

  .navLink {
    color: #ff000091;
  }

  .littleNav img:hover {}

  .insta {
    width: 30px;
    height: 30px;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    margin-right: 3%;
  }

  .insta:hover {

    cursor: pointer;
  }

  .cloudsMob {
    display: none;
  }

  .connectMob {
    display: none;
  }

  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0);
  }

  .allWrap {
    background-color: rgb(255, 255, 255);
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    display: flex;
    flex-flow: column nowrap;
    
  }

  .cont {
    background-color: #000000;
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
  }

  .in2 p {
    font-size: 28px;
    text-align: center;
    font-family: "Iomanoid";
    letter-spacing: 4px;
    text-shadow: 0 0 10px #ffdc64;
    color: #ffdc64;

  }

  .in2 img {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .video {
    width: 100%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: -20px;
  }

  .introduction {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    flex-flow: column nowrap;
  }

  .twitter img {
    width: 50px;
    height: 50px;
    margin-left: 30px;
    cursor: pointer;
    opacity: 0.6;
    transition: 0.2s ease;
    margin-top: -3%;
    z-index: 200000000000;

  }

  .twitter {
    z-index: 200000000000;
  }

  .twitter img:hover {
    opacity: 100%;
    z-index: 200000000000;

  }

  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-size: cover;
    padding-top: 5%;
    padding-bottom: 5%;
  }

  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-size: cover;
    padding-top: 100px;
    padding-bottom: 50px;
  }

  .boxWrap2Sub {
    justify-content: center;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }

  .storyH {
    font-size: 40px;
    color: black;
    text-align: center;
    margin-bottom: 10px;
  }

  .storyMain {
    font-size: 30px;
    color: rgb(255, 255, 255);
    text-align: center;
    margin-bottom: 50px;
    margin-top: 10vh;
  }

  .about {
    color: rgb(0, 0, 0);
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
  }

  .about2 {
    color: rgb(0, 0, 0);
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: center;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  .aboutP {
    width: 100%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .aboutP p {
    font-size: 15px;
    line-height: 35px;
    letter-spacing: 1px;
  }

  .aboutP2 {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .aboutP2 p {
    font-size: 15px;
    line-height: 35px;
    letter-spacing: 1px;
    color: rgb(0, 0, 0);
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.048);
    background-color: #0000001f;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    margin-top: 10px;
    padding-bottom: 10px;
  }

  .aboutP3 {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .aboutP3 p {
    font-size: 15px;
    line-height: 35px;
    letter-spacing: 1px;
    color: rgb(0, 0, 0);
  }

  .aboutP3 div {
    font-size: 15px;
    line-height: 35px;
    letter-spacing: 1px;
    color: rgb(0, 0, 0);
    width: 90%;
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .line {
    width: 100%;
    height: 2px;
    background-color: #c0bcbc;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  }

  .pic {
    width: 100%;
    height: 100%;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 3%;
  }

  .logos {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
  }

  .logos_mobile {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
    display: none;
  }

  .logoF {
    text-align: center;
    margin-bottom: 10px;
    color: rgb(0, 0, 0);
    font-size: 20px
  }

  .copyright {
    color: rgb(0, 0, 0);
    text-align: center;
    font-size: 15px;
  }

  .footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0);
    padding-top: 50px;
    padding-bottom: 50px;
    width: 100%;
  }

  .devs img:hover {
    width: 30px;
    height: 30px;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #ffffff;
    display: none;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 100%;


  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Barlow Condensed', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    color: rgb(0, 0, 0);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.3s ease;
  }

  .connect div:hover {
    color: rgb(146, 146, 146);
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }

  .osMobile {
    width: 33px;
    height: 33px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    z-index: 10000000;
    margin-right: 20px
  }

  .osMobile:hover {

    cursor: pointer;
  }

  #fontSize {
    font-size: 16px;
    z-index: 10000000;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }

  /* CSS */
  .button-62 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    background-color: #bb5a3c;
    font-size: 18px;
    padding: 13px;
    width: 200px;
    color: black;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    transition: 0.3s ease;
    border-radius: 7px;
    border: none;
    font-weight: 500;
  }

  .button-62:hover {
    color: rgb(219, 219, 219);
  }

  .logo2 img {
    width: 70px;
    height: 70px;
  }

  .downImg {
    width: 35px;
    -webkit-animation: arrow_down_animation 2s ease-in-out infinite;
            animation: arrow_down_animation 2s ease-in-out infinite;
    cursor: pointer;
  }


  @-webkit-keyframes arrow_down_animation {

    0% {
      -webkit-transform: translate3d(0, 0, 0) scale(1, 1);
              transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      -webkit-transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
              transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;

    }

  }


  @keyframes arrow_down_animation {

    0% {
      -webkit-transform: translate3d(0, 0, 0) scale(1, 1);
              transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      -webkit-transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
              transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;

    }

  }

  .downImgDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -25px;

  }

  .down {
    text-align: center;
    background-color: rgba(0, 0, 0, 0);
  }

  .stickyBtn2 {
    width: 35px;
    height: 35px;
    opacity: 0.5;
    transition: 0.5s ease;
    z-index: 1;
  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
    margin-left: 88%;
    margin-top: -5%;
    cursor: pointer;

    z-index: 1;

  }

  .stickyBtn2:hover {

    opacity: 100%;
    z-index: 1;

  }

  .clouds2 {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .cloud {
    position: absolute;
    top: 0;

  }

  .cloud1 {
    left: 10vw;

  }

  .cloud1 img {
    width: 120%;
    margin-top: -13%;
  }

  .cloud2 img {
    width: 70%;
    margin-top: -8%;
  }

  .cloud3 img {
    width: 100%;

  }

  .cloud1-1 {
    left: 70vw;
    -webkit-animation-name: move-clouds;
            animation-name: move-clouds;
    -webkit-animation-duration: 600s;
            animation-duration: 600s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
  }

  .cloud2 {
    left: 5px;
    -webkit-animation-name: move-clouds;
            animation-name: move-clouds;
    -webkit-animation-duration: 200s;
            animation-duration: 200s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
  }

  .cloud3 {
    left: 3px;

  }

  .cloud3-3 {
    left: 10px;
    -webkit-animation-name: move-clouds;
            animation-name: move-clouds;
    -webkit-animation-duration: 480s;
            animation-duration: 480s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
  }

  .cloud4 {
    left: 10vw;
  }

  .cloud4 img {
    width: 70%;
    margin-top: -5%;
    margin-left: -5%;
  }

  .cloud3 img {
    width: 25%;
  }

  @-webkit-keyframes move-clouds {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(110vw);
              transform: translateX(110vw);
    }
  }

  @keyframes move-clouds {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(110vw);
              transform: translateX(110vw);
    }
  }

  .cloudsLine img {
    width: 100%;
    display: flex;
  }

  .Q {
    font-size: 30px;
    color: white;
    font-weight: 500;
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ecc46d;
  }

  .rmCon {
    color: white;
    font-size: 18px;
  }

  .rmConAndImg {
    margin-bottom: 30px;
    margin-top: 30px;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .rmConMain {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .rmCon img {
    width: 30px;
    height: 30px;
  }

  .pillar1 {
    width: 10.3%;
  }

  .pillar2 {
    width: 10.3%;
  }

  .pillars {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    margin-top: -40%;
  }

  .line {
    width: 100%;
  }

  .walletBtn {
    background-color: #000000;
    border: none;
    font-size: 12px;
    color: white;
    padding: 15px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    transition: 0.3s ease;
  }

  .walletBtn:hover {
    background-color: #3b3b3b;
  }

  .connect2-2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .mintDiv {
    padding-top: 6%;
    padding-bottom: 6%;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    border: 1px #7f8082 solid;
    z-index: 10000000;

  }

  .parrot {
    width: 90%;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: black;
    letter-spacing: 1px;
    margin-top: 1%;
  }

  .price {
    text-align: center;
    font-size: 23px;
    color: rgb(0, 0, 0);
    margin-top: 4%;
    display: flex;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .maticP {
    font-size: 17px;
    margin-left: 15px;
    color: rgba(0, 0, 0, 0.774);
  }


  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }


  .center {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .btnfos-0-2 {
    font-size: 32px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 60px;
    border: none;

    cursor: pointer;
    background-color: #6cfc1900;
    color: black;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: 1px solid rgba(0, 0, 0, 0.301);
    z-index: 10000000;

  }

  .btnfos-0-2:hover:active {
    background-color: #bbbbbb;
  }

  .btnfos-0-2-2:hover:active {
    background-color: #bbbbbb;
  }

  .btnfos-0-3 {
    margin-top: 20px;
    background-color: rgb(0, 0, 0);
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 25px;
    padding-left: 30px;
    padding-right: 30px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    border: none;
    z-index: 10000000;
    transition: 0.3s ease;
  }


  .nftamount {
    color: #2b2b2b;
    font-size: 75px;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;

  }

  .successfully {
    text-align: center;
    margin-top: 3%;
    color: #000a42;
    font-size: 25px;
  }

  .errorMessage {
    margin-top: 3%;
    font-size: 16px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(43, 43, 43);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(43, 43, 43);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
    font-size: 35px;

  }

  @-webkit-keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(29, 29, 29);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(29, 29, 29),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(29, 29, 29);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(29, 29, 29),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }


  /*.............. FAQ ..............*/


  summary {
    font-size: 15px;
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border: #0000003a 1px solid;
    color: rgb(0, 0, 0);
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }

  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 95%;
    color: rgb(0, 0, 0);
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {
    color: rgb(0, 0, 0);
    padding-bottom: 20px;
    text-align: left;
    font-size: 16px;

  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  }

  .images2 {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
  }

  .images2 img {
    width: 95%;
    height: 95%;
    margin-left: auto;
    margin-right: auto;
  }

  .images2 img:hover {}
}

@media screen and (min-width: 500px) and (max-width: 768px) {
  .vidCont6 {
    display: none;
  }

  .vidCont4 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1%;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border: 2px solid black;
  }

  .vidCont1 {
    display: none;
  }

  .vidCont5 {
    display: none;
  }

  .vidCont2 {
    display: none;
  }

  .vidCont3 {
    display: none;
  }

  .socialCreator2 img {
    width: 25px;
    height: 25px;
    margin-left: 10%;
    margin-right: 10%;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .icons2 {
    display: flex;
    justify-content: center;
  }

  .logo3 img {
    width: 50px;
    height: 50px;
  }

  .miniBarMain {
    padding-bottom: 10px;
    padding-top: 10px;
    width:  100vw;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;    padding-left: 5%;
    padding-right: 5%;
    background-color: rgb(255, 255, 255);
    display: flex;
    justify-content: space-between;
  }

  .miniBar {
    display: flex;
    justify-content: space-between;
  }

  .navbarMainImgSet {
    padding-right: 30px;
     position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000000000000000000;
    align-items: center;
    display: flex;
  }

  .navbar {
    width: 30px;
    justify-content: flex-end;
    cursor: pointer;    
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    display: block;
    z-index: 1000000000000000000;
    align-items: center;

  }

  #fontSize2 {
    font-size: 15px;
    color: white;
    text-align: left;
  }

  #fontSize2:hover {
    color: #818181;
  }

  .littleNav div {
    text-align: left;
    padding: 30px;
    cursor: pointer;
    border-top: rgba(255, 255, 255, 0.233) 1px solid;
  }

  .littleNav {
    background-color: #1a1a1ad8;
    width: 100%;
    height: -webkit-max-content;
    height: max-content;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000000000;
    flex-flow: column wrap;
    display: flex;
    border-bottom: 5px rgba(49, 49, 49, 0.603) solid;
  }

  .hidden {
    display: none;
  }

  .close {
    width: 35px;
    height: 35px;
    justify-content: flex-end;
    margin-left: auto;
    cursor: pointer;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
  }

  .navLink {
    color: #ff000091;
  }

  .littleNav img:hover {}

  .insta {
    width: 30px;
    height: 30px;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    margin-right: 3%;
  }

  .insta:hover {

    cursor: pointer;
  }

  .cloudsMob {
    display: none;
  }

  .connectMob {
    display: none;
  }

  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0);
  }

  .allWrap {
    background-color: rgb(255, 255, 255);
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    display: flex;
    flex-flow: column nowrap;
    
  }

  .cont {
    background-color: #000000;
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
  }

  .in2 p {
    font-size: 28px;
    text-align: center;
    font-family: "Iomanoid";
    letter-spacing: 4px;
    text-shadow: 0 0 10px #ffdc64;
    color: #ffdc64;

  }

  .in2 img {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .video {
    width: 100%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: -20px;
  }

  .introduction {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    flex-flow: column nowrap;
  }

  .twitter img {
    width: 50px;
    height: 50px;
    margin-left: 30px;
    cursor: pointer;
    opacity: 0.6;
    transition: 0.2s ease;
    margin-top: -3%;
    z-index: 200000000000;

  }

  .twitter {
    z-index: 200000000000;
  }

  .twitter img:hover {
    opacity: 100%;
    z-index: 200000000000;

  }

  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-size: cover;
    padding-top: 5%;
    padding-bottom: 5%;
  }

  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-size: cover;
    padding-top: 100px;
    padding-bottom: 50px;
  }

  .boxWrap2Sub {
    justify-content: center;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }

  .storyH {
    font-size: 50px;
    color: black;
    text-align: center;
    margin-bottom: 10px;
  }

  .storyMain {
    font-size: 50px;
    color: rgb(255, 255, 255);
    text-align: center;
    margin-bottom: 50px;
    margin-top: 25px;
  }

  .about {
    color: rgb(0, 0, 0);
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
  }

  .about2 {
    color: rgb(0, 0, 0);
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: center;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  .aboutP {
    width: 100%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .aboutP p {
    font-size: 15px;
    line-height: 35px;
    letter-spacing: 1px;
  }

  .aboutP2 {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .aboutP2 p {
    font-size: 15px;
    line-height: 35px;
    letter-spacing: 1px;
    color: rgb(0, 0, 0);
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.048);
    background-color: #0000001f;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    margin-top: 10px;
    padding-bottom: 10px;
  }

  .aboutP3 {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .aboutP3 p {
    font-size: 15px;
    line-height: 35px;
    letter-spacing: 1px;
    color: rgb(0, 0, 0);
  }

  .aboutP3 div {
    font-size: 15px;
    line-height: 35px;
    letter-spacing: 1px;
    color: rgb(0, 0, 0);
    width: 90%;
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .line {
    width: 100%;
    height: 2px;
    background-color: #c0bcbc;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  }

  .pic {
    width: 100%;
    height: 100%;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 3%;
  }

  .logos {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
  }

  .logos_mobile {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
    display: none;
  }

  .logoF {
    text-align: center;
    margin-bottom: 10px;
    color: rgb(0, 0, 0);
    font-size: 20px
  }

  .copyright {
    color: rgb(0, 0, 0);
    text-align: center;
    font-size: 15px;
  }

  .footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0);
    padding-top: 50px;
    padding-bottom: 50px;
    width: 100%;
  }

  .devs img:hover {
    width: 30px;
    height: 30px;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #ffffff;
    display: none;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 100%;


  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Barlow Condensed', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    color: rgb(0, 0, 0);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.3s ease;
  }

  .connect div:hover {
    color: rgb(146, 146, 146);
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }

  .osMobile {
    width: 33px;
    height: 33px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    z-index: 10000000;
    margin-right: 20px
  }

  .osMobile:hover {

    cursor: pointer;
  }

  #fontSize {
    font-size: 16px;
    z-index: 10000000;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }

  /* CSS */
  .button-62 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    background-color: #bb5a3c;
    font-size: 18px;
    padding: 13px;
    width: 200px;
    color: black;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    transition: 0.3s ease;
    border-radius: 7px;
    border: none;
    font-weight: 500;
  }

  .button-62:hover {
    color: rgb(219, 219, 219);
  }

  .logo2 img {
    width: 80px;
    height: 80px;
  }

  .logo2 {}

  .downImg {
    width: 35px;
    -webkit-animation: arrow_down_animation 2s ease-in-out infinite;
            animation: arrow_down_animation 2s ease-in-out infinite;
    cursor: pointer;
  }


  @-webkit-keyframes arrow_down_animation {

    0% {
      -webkit-transform: translate3d(0, 0, 0) scale(1, 1);
              transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      -webkit-transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
              transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;

    }

  }


  @keyframes arrow_down_animation {

    0% {
      -webkit-transform: translate3d(0, 0, 0) scale(1, 1);
              transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      -webkit-transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
              transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;

    }

  }

  .downImgDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -25px;

  }

  .down {
    text-align: center;
    background-color: rgba(0, 0, 0, 0);
  }

  .stickyBtn2 {
    width: 35px;
    height: 35px;
    opacity: 0.5;
    transition: 0.5s ease;
    z-index: 1;
  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
    margin-left: 88%;
    margin-top: -5%;
    cursor: pointer;

    z-index: 1;

  }

  .stickyBtn2:hover {

    opacity: 100%;
    z-index: 1;

  }

  .clouds2 {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .cloud {
    position: absolute;
    top: 0;

  }

  .cloud1 {
    left: 10vw;

  }

  .cloud1 img {
    width: 120%;
    margin-top: -13%;
  }

  .cloud2 img {
    width: 70%;
    margin-top: -8%;
  }

  .cloud3 img {
    width: 100%;

  }

  .cloud1-1 {
    left: 70vw;
    -webkit-animation-name: move-clouds;
            animation-name: move-clouds;
    -webkit-animation-duration: 600s;
            animation-duration: 600s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
  }

  .cloud2 {
    left: 5px;
    -webkit-animation-name: move-clouds;
            animation-name: move-clouds;
    -webkit-animation-duration: 200s;
            animation-duration: 200s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
  }

  .cloud3 {
    left: 3px;

  }

  .cloud3-3 {
    left: 10px;
    -webkit-animation-name: move-clouds;
            animation-name: move-clouds;
    -webkit-animation-duration: 480s;
            animation-duration: 480s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
  }

  .cloud4 {
    left: 10vw;
  }

  .cloud4 img {
    width: 70%;
    margin-top: -5%;
    margin-left: -5%;
  }

  .cloud3 img {
    width: 25%;
  }

  @-webkit-keyframes move-clouds {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(110vw);
              transform: translateX(110vw);
    }
  }

  @keyframes move-clouds {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(110vw);
              transform: translateX(110vw);
    }
  }

  .cloudsLine img {
    width: 100%;
    display: flex;
  }

  .Q {
    font-size: 30px;
    color: white;
    font-weight: 500;
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ecc46d;
  }

  .rmCon {
    color: white;
    font-size: 18px;
  }

  .rmConAndImg {
    margin-bottom: 30px;
    margin-top: 30px;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .rmConMain {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .rmCon img {
    width: 30px;
    height: 30px;
  }

  .pillar1 {
    width: 10.3%;
  }

  .pillar2 {
    width: 10.3%;
  }

  .pillars {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    margin-top: -40%;
  }

  .line {
    width: 100%;
  }

  .walletBtn {
    background-color: #000000;
    border: none;
    font-size: 15px;
    color: white;
    padding: 15px;
    width: 200px;
    transition: 0.3s ease;
  }

  .walletBtn:hover {
    background-color: #3b3b3b;
  }

  .connect2-2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .mintDiv {
    padding-top: 6%;
    padding-bottom: 6%;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    border: 1px #7f8082 solid;
    z-index: 10000000;

  }

  .parrot {
    width: 90%;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: black;
    letter-spacing: 1px;
    margin-top: 1%;
  }

  .price {
    text-align: center;
    font-size: 23px;
    color: rgb(0, 0, 0);
    margin-top: 4%;
    display: flex;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .maticP {
    font-size: 17px;
    margin-left: 15px;
    color: rgba(0, 0, 0, 0.774);
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }


  .center {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .btnfos-0-2 {
    font-size: 32px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 60px;
    border: none;

    cursor: pointer;
    background-color: #6cfc1900;
    color: black;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: 1px solid rgba(0, 0, 0, 0.301);
    z-index: 10000000;

  }

  .btnfos-0-2:hover:active {
    background-color: #bbbbbb;
  }

  .btnfos-0-2-2:hover:active {
    background-color: #bbbbbb;
  }

  .btnfos-0-3 {
    margin-top: 20px;
    background-color: rgb(0, 0, 0);
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 25px;
    padding-left: 30px;
    padding-right: 30px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    border: none;
    z-index: 10000000;
    transition: 0.3s ease;
  }



  .nftamount {
    color: #2b2b2b;
    font-size: 75px;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;

  }

  .successfully {
    text-align: center;
    margin-top: 3%;
    color: #000a42;
    font-size: 25px;
  }

  .errorMessage {
    margin-top: 3%;
    font-size: 18px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(43, 43, 43);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(43, 43, 43);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
    font-size: 35px;

  }

  @-webkit-keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(29, 29, 29);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(29, 29, 29),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(29, 29, 29);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(29, 29, 29),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }


  /*.............. FAQ ..............*/


  summary {
    font-size: 15px;
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border: #0000003a 1px solid;
    color: rgb(0, 0, 0);
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }

  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 95%;
    color: rgb(0, 0, 0);
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {
    color: rgb(0, 0, 0);
    padding-bottom: 20px;
    text-align: left;
    font-size: 16px;

  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  }

  .images2 {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
  }

  .images2 img {
    width: 95%;
    height: 95%;

    margin-left: auto;
    margin-right: auto;
  }

  .images2 img:hover {}
}

@media screen and (min-width: 768px) and (max-width: 992px) {
  .vidCont6 {
    display: none;
  }

  .vidCont3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1%;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border: 2px solid black;
  }

  .vidCont1 {
    display: none;
  }

  .vidCont2 {
    display: none;
  }

  .vidCont4 {
    display: none;
  }

  .vidCont5 {
    display: none;
  }
  .socialCreator2 img {
    width: 25px;
    height: 25px;
    margin-left: 10%;
    margin-right: 10%;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .icons2 {
    display: flex;
    justify-content: center;
  }

  .logo3 img {
    width: 50px;
    height: 50px;
  }

  .miniBarMain {
    padding-bottom: 10px;
    padding-top: 10px;
    width:  100vw;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding-left: 5%;
    padding-right: 5%;
    background-color: rgb(255, 255, 255);
    display: flex;
    justify-content: space-between;
  }

  .miniBar {
    display: flex;
    justify-content: space-between;
  }

  .navbarMainImgSet {
    padding-right: 30px;
     position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000000000000000000;
    align-items: center;
    display: flex;
  }

  .navbar {
    width: 40px;
    justify-content: flex-end;
    cursor: pointer;    
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    display: block;
    z-index: 1000000000000000000;
    align-items: center;

  }

  #fontSize2 {
    font-size: 16px;
    color: white;
    text-align: left;
  }

  #fontSize2:hover {
    color: #818181;
  }

  .littleNav div {
    text-align: left;
    padding: 30px;
    cursor: pointer;
    border-top: rgba(255, 255, 255, 0.233) 1px solid;
  }

  .littleNav {
    background-color: #1a1a1ad8;
    width: 100%;
    height: -webkit-max-content;
    height: max-content;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000000000;
    flex-flow: column wrap;
    display: flex;
    border-bottom: 5px rgba(49, 49, 49, 0.603) solid;
  }

  .hidden {
    display: none;
  }

  .close {
    width: 35px;
    height: 35px;
    justify-content: flex-end;
    margin-left: auto;
    cursor: pointer;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
  }

  .navLink {
    color: #ff000091;
  }

  .littleNav img:hover {}

  .insta {
    width: 30px;
    height: 30px;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    margin-right: 3%;
  }

  .insta:hover {

    cursor: pointer;
  }

  .cloudsMob {
    display: none;
  }

  .connectMob {
    display: none;
  }

  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0);
  }

  .allWrap {
    background-color: rgb(255, 255, 255);
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    display: flex;
    flex-flow: column nowrap;
    
  }

  .cont {
    background-color: #000000;
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
  }

  .in2 p {
    font-size: 28px;
    text-align: center;
    font-family: "Iomanoid";
    letter-spacing: 4px;
    text-shadow: 0 0 10px #ffdc64;
    color: #ffdc64;

  }

  .in2 img {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .video {
    width: 100%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: -20px;
  }

  .twitter img {
    width: 50px;
    height: 50px;
    margin-left: 30px;
    cursor: pointer;
    opacity: 0.6;
    transition: 0.2s ease;
    margin-top: -3%;
    z-index: 200000000000;

  }

  .twitter {
    z-index: 200000000000;
  }

  .twitter img:hover {
    opacity: 100%;
    z-index: 200000000000;

  }

  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-size: cover;
    padding-top: 5%;
    padding-bottom: 5%;
  }

  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-size: cover;
    padding-top: 100px;
    padding-bottom: 50px;
  }

  .boxWrap2Sub {
    justify-content: center;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }

  .storyH {
    font-size: 50px;
    color: black;
    text-align: center;
    margin-bottom: 50px;
  }

  .storyMain {
    font-size: 50px;
    color: rgb(255, 255, 255);
    text-align: center;
    margin-bottom: 50px;
    margin-top: 25px;
  }

  .about {
    color: rgb(0, 0, 0);
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
  }

  .about2 {
    color: rgb(0, 0, 0);
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: center;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  .aboutP {
    width: 100%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .aboutP p {
    font-size: 15px;
    line-height: 35px;
    letter-spacing: 1px;
  }

  .aboutP2 {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .aboutP2 p {
    font-size: 15px;
    line-height: 35px;
    letter-spacing: 1px;
    color: rgb(0, 0, 0);
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.048);
    background-color: #0000001f;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    margin-top: 10px;
    padding-bottom: 10px;
  }

  .aboutP3 {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .aboutP3 p {
    font-size: 15px;
    line-height: 35px;
    letter-spacing: 1px;
    color: rgb(0, 0, 0);
  }

  .aboutP3 div {
    font-size: 15px;
    line-height: 35px;
    letter-spacing: 1px;
    color: rgb(0, 0, 0);
    width: 55%;
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .line {
    width: 100%;
    height: 2px;
    background-color: #c0bcbc;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  }

  .pic {
    width: 100%;
    height: 100%;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 3%;
  }

  .logos {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
  }

  .logos_mobile {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
    display: none;
  }

  .logoF {
    text-align: center;
    margin-bottom: 10px;
    color: rgb(0, 0, 0);
    font-size: 22px
  }

  .copyright {
    color: rgb(0, 0, 0);
    text-align: center;
    font-size: 16px;
  }

  .footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0);
    padding-top: 50px;
    padding-bottom: 50px;
    width: 100%;
  }

  .devs img:hover {
    width: 30px;
    height: 30px;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #ffffff;
    display: none;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 100%;


  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Barlow Condensed', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    color: rgb(0, 0, 0);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.3s ease;
  }

  .connect div:hover {
    color: rgb(146, 146, 146);
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }

  .osMobile {
    width: 33px;
    height: 33px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    z-index: 10000000;
    margin-right: 20px
  }

  .osMobile:hover {

    cursor: pointer;
  }

  #fontSize {
    font-size: 16px;
    z-index: 10000000;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }

  /* CSS */
  .button-62 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    background-color: #bb5a3c;
    font-size: 18px;
    padding: 13px;
    width: 200px;
    color: black;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    transition: 0.3s ease;
    border-radius: 7px;
    border: none;
    font-weight: 500;
  }

  .button-62:hover {
    color: rgb(219, 219, 219);
  }

  .logo2 img {
    width: 110px;
    height: 110px;
  }

  .logo2 {}

  .downImg {
    width: 35px;
    -webkit-animation: arrow_down_animation 2s ease-in-out infinite;
            animation: arrow_down_animation 2s ease-in-out infinite;
    cursor: pointer;
  }


  @-webkit-keyframes arrow_down_animation {

    0% {
      -webkit-transform: translate3d(0, 0, 0) scale(1, 1);
              transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      -webkit-transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
              transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;

    }

  }


  @keyframes arrow_down_animation {

    0% {
      -webkit-transform: translate3d(0, 0, 0) scale(1, 1);
              transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      -webkit-transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
              transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;

    }

  }

  .downImgDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -25px;

  }

  .down {
    text-align: center;
    background-color: rgba(0, 0, 0, 0);
  }

  .stickyBtn2 {
    width: 50px;
    height: 50px;
    opacity: 0.5;
    transition: 0.5s ease;
    z-index: 1;
  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
    margin-left: 90%;
    margin-top: -5%;
    cursor: pointer;

    z-index: 1;

  }

  .stickyBtn2:hover {

    opacity: 100%;
    z-index: 1;

  }

  .clouds2 {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .cloud {
    position: absolute;
    top: 0;

  }

  .cloud1 {
    left: 10vw;

  }

  .cloud1 img {
    width: 120%;
    margin-top: -13%;
  }

  .cloud2 img {
    width: 70%;
    margin-top: -8%;
  }

  .cloud3 img {
    width: 100%;

  }

  .cloud1-1 {
    left: 70vw;
    -webkit-animation-name: move-clouds;
            animation-name: move-clouds;
    -webkit-animation-duration: 600s;
            animation-duration: 600s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
  }

  .cloud2 {
    left: 5px;
    -webkit-animation-name: move-clouds;
            animation-name: move-clouds;
    -webkit-animation-duration: 200s;
            animation-duration: 200s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
  }

  .cloud3 {
    left: 3px;

  }

  .cloud3-3 {
    left: 10px;
    -webkit-animation-name: move-clouds;
            animation-name: move-clouds;
    -webkit-animation-duration: 480s;
            animation-duration: 480s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
  }

  .cloud4 {
    left: 10vw;
  }

  .cloud4 img {
    width: 70%;
    margin-top: -5%;
    margin-left: -5%;
  }

  .cloud3 img {
    width: 25%;
  }

  @-webkit-keyframes move-clouds {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(110vw);
              transform: translateX(110vw);
    }
  }

  @keyframes move-clouds {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(110vw);
              transform: translateX(110vw);
    }
  }

  .cloudsLine img {
    width: 100%;
    display: flex;
  }

  .Q {
    font-size: 30px;
    color: white;
    font-weight: 500;
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ecc46d;
  }

  .rmCon {
    color: white;
    font-size: 18px;
  }

  .rmConAndImg {
    margin-bottom: 30px;
    margin-top: 30px;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .rmConMain {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .rmCon img {
    width: 30px;
    height: 30px;
  }

  .pillar1 {
    width: 10.3%;
  }

  .pillar2 {
    width: 10.3%;
  }

  .pillars {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    margin-top: -40%;
  }

  .line {
    width: 100%;
  }

  .walletBtn {
    background-color: #000000;
    border: none;
    font-size: 16px;
    color: white;
    padding: 20px;
    width: 220px;
    transition: 0.3s ease;
  }

  .walletBtn:hover {
    background-color: #3b3b3b;
  }

  .connect2-2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px #7f8082 solid;
    z-index: 10000000;

  }

  .parrot {
    width: 70%;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: black;
    letter-spacing: 1px;
    margin-top: 1%;
  }

  .price {
    text-align: center;
    font-size: 25px;
    color: rgb(0, 0, 0);
    margin-top: 4%;
    display: flex;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .maticP {
    font-size: 18px;
    margin-left: 15px;
    color: rgba(0, 0, 0, 0.774);
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }


  .center {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;

    cursor: pointer;
    background-color: #6cfc1900;
    color: black;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: 1px solid rgba(0, 0, 0, 0.301);
    z-index: 10000000;

  }

  .btnfos-0-2:hover:active {
    background-color: #bbbbbb;
  }

  .btnfos-0-2-2:hover:active {
    background-color: #bbbbbb;
  }

  .btnfos-0-3 {
    margin-top: 20px;
    background-color: rgb(0, 0, 0);
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    padding-left: 30px;
    padding-right: 30px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    border: none;
    z-index: 10000000;
    transition: 0.3s ease;
  }



  .nftamount {
    color: #2b2b2b;
    font-size: 85px;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;

  }

  .successfully {
    text-align: center;
    margin-top: 3%;
    color: #000a42;
    font-size: 30px;
  }

  .errorMessage {
    margin-top: 3%;
    font-size: 20px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(43, 43, 43);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(43, 43, 43);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
    font-size: 35px;

  }

  @-webkit-keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(29, 29, 29);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(29, 29, 29),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(29, 29, 29);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(29, 29, 29),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }


  /*.............. FAQ ..............*/


  summary {
    font-size: 18px;
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border: #0000003a 1px solid;
    color: rgb(0, 0, 0);
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }

  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 95%;
    color: rgb(0, 0, 0);
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {
    color: rgb(0, 0, 0);
    padding-bottom: 20px;
    text-align: left;
    font-size: 18px;

  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  }

  .images2 {
    display: flex;
    justify-content: space-evenly;
  }

  .images2 img {
    width: 40%;
    height: 40%;

  }

  .images2 img:hover {}
}

@media screen and (min-width: 992px) and (max-width: 1200px) {

  .vidCont6 {
    display: none;
  }

  .vidCont5 {
    display: none;
  }

  .vidCont4 {
    display: none;
  }

  .vidCont3 {
    display: none;
  }

  .vidCont2 {
    display: none;
  }

  .vidCont1 {
    display: none;
  }

  .vidCont2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1%;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border: 2px solid black;
  }

  .vidCont1 {
    display: none;
  }

  .socialCreator2 img {
    width: 25px;
    height: 25px;
    margin-left: 10%;
    margin-right: 10%;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .icons2 {
    display: flex;
    justify-content: center;
  }

  .logo3 img {
    width: 50px;
    height: 50px;
  }

  .miniBarMain {
    padding-bottom: 10px;
    padding-top: 10px;
    width:  100vw;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;    padding-left: 5%;
    padding-right: 5%;
    background-color: rgb(255, 255, 255);
    display: flex;
    justify-content: space-between;
  }

  .miniBar {
    display: flex;
    justify-content: space-between;
  }

  .navbarMainImgSet {
    padding-right: 30px;
     position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000000000000000000;
    align-items: center;
    display: flex;
  }

  .navbar {
    width: 40px;
    justify-content: flex-end;
    cursor: pointer;    
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    display: block;
    z-index: 1000000000000000000;
    align-items: center;

  }

  #fontSize2 {
    font-size: 16px;
    color: white;
    text-align: left;
  }

  #fontSize2:hover {
    color: #818181;
  }

  .littleNav div {
    text-align: left;
    padding: 30px;
    cursor: pointer;
    border-top: rgba(255, 255, 255, 0.233) 1px solid;
  }

  .littleNav {
    background-color: #1a1a1ad8;
    width: 100%;
    height: -webkit-max-content;
    height: max-content;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000000000;
    flex-flow: column wrap;
    display: flex;
    border-bottom: 5px rgba(49, 49, 49, 0.603) solid;
  }

  .hidden {
    display: none;
  }

  .close {
    width: 35px;
    height: 35px;
    justify-content: flex-end;
    margin-left: auto;
    cursor: pointer;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
  }

  .navLink {
    color: #ff000091;
  }

  .littleNav img:hover {}

  .insta {
    width: 30px;
    height: 30px;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    margin-right: 3%;
  }

  .insta:hover {

    cursor: pointer;
  }

  .cloudsMob {
    display: none;
  }

  .connectMob {
    display: none;
  }

  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0);
  }

  .allWrap {
    background-color: rgb(255, 255, 255);
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    display: flex;
    flex-flow: column nowrap;
    
  }

  .cont {
    background-color: #000000;
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
  }

  .in2 p {
    font-size: 28px;
    text-align: center;
    font-family: "Iomanoid";
    letter-spacing: 4px;
    text-shadow: 0 0 10px #ffdc64;
    color: #ffdc64;

  }

  .in2 img {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .video {
    width: 90%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: -20px;
  }

  .twitter img {
    width: 50px;
    height: 50px;
    margin-left: 30px;
    cursor: pointer;
    opacity: 0.6;
    transition: 0.2s ease;
    margin-top: -3%;
    z-index: 200000000000;

  }

  .twitter {
    z-index: 200000000000;
  }

  .twitter img:hover {
    opacity: 100%;
    z-index: 200000000000;

  }

  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-size: cover;
    padding-top: 5%;
    padding-bottom: 5%;
  }

  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-size: cover;
    padding-top: 100px;
    padding-bottom: 50px;
  }

  .boxWrap2Sub {
    justify-content: space-between;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }

  .storyH {
    font-size: 50px;
    color: black;
    text-align: center;
    margin-bottom: 50px;
  }

  .storyMain {
    font-size: 50px;
    color: rgb(255, 255, 255);
    text-align: center;
    margin-bottom: 50px;
    margin-top: 25px;
  }

  .about {
    color: rgb(0, 0, 0);
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
  }

  .about2 {
    color: rgb(0, 0, 0);
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: center;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  .aboutP {
    width: 35%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .aboutP p {
    font-size: 16px;
    line-height: 35px;
    letter-spacing: 1px;
  }

  .aboutP2 {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .aboutP2 p {
    font-size: 16px;
    line-height: 35px;
    letter-spacing: 1px;
    color: rgb(0, 0, 0);
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.048);
    background-color: #0000001f;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    margin-top: 10px;
    padding-bottom: 10px;
  }

  .aboutP3 {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .aboutP3 p {
    font-size: 16px;
    line-height: 35px;
    letter-spacing: 1px;
    color: rgb(0, 0, 0);
  }

  .aboutP3 div {
    font-size: 16px;
    line-height: 35px;
    letter-spacing: 1px;
    color: rgb(0, 0, 0);
    width: 55%;
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .line {
    width: 100%;
    height: 2px;
    background-color: #c0bcbc;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  }

  .pic {
    width: 50%;
    height: 50%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .logos {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
  }

  .logos_mobile {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
    display: none;
  }

  .logoF {
    text-align: center;
    margin-bottom: 10px;
    color: rgb(0, 0, 0);
    font-size: 22px
  }

  .copyright {
    color: rgb(0, 0, 0);
    text-align: center;
    font-size: 16px;
  }

  .footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0);
    padding-top: 50px;
    padding-bottom: 50px;
    width: 100%;
  }

  .devs img:hover {
    width: 30px;
    height: 30px;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #ffffff;
    display: none;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 100%;


  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Barlow Condensed', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    color: rgb(0, 0, 0);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.3s ease;
  }

  .connect div:hover {
    color: rgb(146, 146, 146);
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }

  .osMobile {
    width: 33px;
    height: 33px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    z-index: 10000000;
    margin-right: 20px
  }

  .osMobile:hover {

    cursor: pointer;
  }

  #fontSize {
    font-size: 16px;
    z-index: 10000000;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }

  /* CSS */
  .button-62 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    background-color: #bb5a3c;
    font-size: 18px;
    padding: 13px;
    width: 200px;
    color: black;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    transition: 0.3s ease;
    border-radius: 7px;
    border: none;
    font-weight: 500;
  }

  .button-62:hover {
    color: rgb(219, 219, 219);
  }

  .logo2 img {
    width: 110px;
    height: 110px;
  }

  .logo2 {}

  .downImg {
    width: 35px;
    -webkit-animation: arrow_down_animation 2s ease-in-out infinite;
            animation: arrow_down_animation 2s ease-in-out infinite;
    cursor: pointer;
  }


  @-webkit-keyframes arrow_down_animation {

    0% {
      -webkit-transform: translate3d(0, 0, 0) scale(1, 1);
              transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      -webkit-transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
              transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;

    }

  }


  @keyframes arrow_down_animation {

    0% {
      -webkit-transform: translate3d(0, 0, 0) scale(1, 1);
              transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      -webkit-transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
              transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;

    }

  }

  .downImgDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -25px;

  }

  .down {
    text-align: center;
    background-color: rgba(0, 0, 0, 0);
  }

  .stickyBtn2 {
    width: 50px;
    height: 50px;
    opacity: 0.5;
    transition: 0.5s ease;
    z-index: 1;
  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
    margin-left: 90%;
    margin-top: -5%;
    cursor: pointer;

    z-index: 1;

  }

  .stickyBtn2:hover {

    opacity: 100%;
    z-index: 1;

  }

  .clouds2 {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .cloud {
    position: absolute;
    top: 0;

  }

  .cloud1 {
    left: 10vw;

  }

  .cloud1 img {
    width: 120%;
    margin-top: -13%;
  }

  .cloud2 img {
    width: 70%;
    margin-top: -8%;
  }

  .cloud3 img {
    width: 100%;

  }

  .cloud1-1 {
    left: 70vw;
    -webkit-animation-name: move-clouds;
            animation-name: move-clouds;
    -webkit-animation-duration: 600s;
            animation-duration: 600s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
  }

  .cloud2 {
    left: 5px;
    -webkit-animation-name: move-clouds;
            animation-name: move-clouds;
    -webkit-animation-duration: 200s;
            animation-duration: 200s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
  }

  .cloud3 {
    left: 3px;

  }

  .cloud3-3 {
    left: 10px;
    -webkit-animation-name: move-clouds;
            animation-name: move-clouds;
    -webkit-animation-duration: 480s;
            animation-duration: 480s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
  }

  .cloud4 {
    left: 10vw;
  }

  .cloud4 img {
    width: 70%;
    margin-top: -5%;
    margin-left: -5%;
  }

  .cloud3 img {
    width: 25%;
  }

  @-webkit-keyframes move-clouds {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(110vw);
              transform: translateX(110vw);
    }
  }

  @keyframes move-clouds {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(110vw);
              transform: translateX(110vw);
    }
  }

  .cloudsLine img {
    width: 100%;
    display: flex;
  }

  .Q {
    font-size: 30px;
    color: white;
    font-weight: 500;
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ecc46d;
  }

  .rmCon {
    color: white;
    font-size: 18px;
  }

  .rmConAndImg {
    margin-bottom: 30px;
    margin-top: 30px;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .rmConMain {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .rmCon img {
    width: 30px;
    height: 30px;
  }

  .pillar1 {
    width: 10.3%;
  }

  .pillar2 {
    width: 10.3%;
  }

  .pillars {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    margin-top: -40%;
  }

  .line {
    width: 100%;
  }

  .walletBtn {
    background-color: #000000;
    border: none;
    font-size: 16px;
    color: white;
    padding: 20px;
    width: 220px;
    transition: 0.3s ease;
  }

  .walletBtn:hover {
    background-color: #3b3b3b;
  }

  .connect2-2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px #7f8082 solid;
    z-index: 10000000;

  }

  .parrot {
    width: 70%;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: black;
    letter-spacing: 1px;
    margin-top: 1%;
  }

  .price {
    text-align: center;
    font-size: 25px;
    color: rgb(0, 0, 0);
    margin-top: 4%;
    display: flex;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .maticP {
    font-size: 18px;
    margin-left: 15px;
    color: rgba(0, 0, 0, 0.774);
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }


  .center {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;

    cursor: pointer;
    background-color: #6cfc1900;
    color: black;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: 1px solid rgba(0, 0, 0, 0.301);
    z-index: 10000000;

  }

  .btnfos-0-2:hover:active {
    background-color: #bbbbbb;
  }

  .btnfos-0-2-2:hover:active {
    background-color: #bbbbbb;
  }

  .btnfos-0-3 {
    margin-top: 20px;
    background-color: rgb(0, 0, 0);
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    padding-left: 30px;
    padding-right: 30px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    border: none;
    z-index: 10000000;
    transition: 0.3s ease;
  }



  .nftamount {
    color: #2b2b2b;
    font-size: 85px;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;

  }

  .successfully {
    text-align: center;
    margin-top: 3%;
    color: #000a42;
    font-size: 30px;
  }

  .errorMessage {
    margin-top: 3%;
    font-size: 20px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(43, 43, 43);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(43, 43, 43);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
    font-size: 35px;

  }

  @-webkit-keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(29, 29, 29);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(29, 29, 29),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(29, 29, 29);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(29, 29, 29),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }


  /*.............. FAQ ..............*/


  summary {
    font-size: 18px;
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border: #0000003a 1px solid;
    color: rgb(0, 0, 0);
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }

  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: rgb(0, 0, 0);
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {
    color: rgb(0, 0, 0);
    padding-bottom: 20px;
    text-align: left;
    font-size: 18px;

  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  }

  .images2 {
    display: flex;
    justify-content: space-evenly;
  }

  .images2 img {
    width: 40%;
    height: 40%;

  }

  .images2 img:hover {}
}

@media screen and (min-width: 1200px) and (max-width: 1920px) {

  .vidCont1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1%;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border: 2px solid black;
  }

  .vidCont5 {
    display: none;
  }

  .vidCont2 {
    display: none;
  }

  .vidCont3 {
    display: none;
  }

  .vidCont4 {
    display: none;
  }

  .vidCont6 {
    display: none;
  }

  .socialCreator2 img {
    width: 25px;
    height: 25px;
    margin-left: 10%;
    margin-right: 10%;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .insta {
    width: 30px;
    height: 30px;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    margin-right: 3%;
  }

  .insta:hover {

    cursor: pointer;
  }

  .cloudsMob {
    display: none;
  }


  .connectMob {
    display: none;
  }

  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0);
  }

  .littleNav {
    display: none;
  }

  .miniBarMain {
    display: none;
  }

  .logosMob {
    display: none;
  }

  .allWrap {
    background-color: rgb(255, 255, 255);
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    display: flex;
    flex-flow: column nowrap;
    
  }

  .cont {
    background-color: #000000;
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
  }

  .in2 p {}

  .in2 img {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .video {
    width: 60%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: -20px;
  }

  .twitter img {
    width: 50px;
    height: 50px;
    margin-left: 30px;
    cursor: pointer;
    opacity: 0.6;
    transition: 0.2s ease;
    margin-top: -3%;
    z-index: 200000000000;

  }

  .twitter {
    z-index: 200000000000;
  }

  .twitter img:hover {
    opacity: 100%;
    z-index: 200000000000;

  }

  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-size: cover;
    padding-top: 5%;
    padding-bottom: 5%;
  }

  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-size: cover;
    padding-top: 100px;
    padding-bottom: 50px;
  }

  .boxWrap2Sub {
    justify-content: space-between;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }

  .storyH {
    font-size: 50px;
    color: black;
    text-align: center;
    margin-bottom: 50px;
  }

  .storyMain {
    font-size: 50px;
    color: rgb(255, 255, 255);
    text-align: center;
    margin-bottom: 50px;
    margin-top: 25px;
  }

  .about {
    color: rgb(0, 0, 0);
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
  }

  .about2 {
    color: rgb(0, 0, 0);
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: center;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  .aboutP {
    width: 35%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .aboutP p {
    font-size: 16px;
    line-height: 35px;
    letter-spacing: 1px;
  }

  .aboutP2 {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .aboutP2 p {
    font-size: 16px;
    line-height: 35px;
    letter-spacing: 1px;
    color: rgb(0, 0, 0);
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.048);
    background-color: #0000001f;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    margin-top: 10px;
    padding-bottom: 10px;
  }

  .aboutP3 {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .aboutP3 p {
    font-size: 16px;
    line-height: 35px;
    letter-spacing: 1px;
    color: rgb(0, 0, 0);
  }

  .aboutP3 div {
    font-size: 16px;
    line-height: 35px;
    letter-spacing: 1px;
    color: rgb(0, 0, 0);
    width: 55%;
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .line {
    width: 100%;
    height: 2px;
    background-color: #c0bcbc;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  }

  .pic {
    width: 55%;
    height: 55%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .logos {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
  }

  .logos_mobile {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
    display: none;
  }

  .logoF {
    text-align: center;
    margin-bottom: 10px;
    color: rgb(0, 0, 0);
    font-size: 22px
  }

  .copyright {
    color: rgb(0, 0, 0);
    text-align: center;
    font-size: 16px;
  }

  .footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0);
    padding-top: 50px;
    padding-bottom: 50px;
    width: 100%;
  }

  .devs img:hover {
    width: 30px;
    height: 30px;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #ffffff;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 100%;


  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Barlow Condensed', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    color: rgb(0, 0, 0);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.3s ease;
  }

  .connect div:hover {
    color: rgb(146, 146, 146);
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-left: auto;
  }

  .icons {
    width: 30px;
    height: 30px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    z-index: 10000000;
    margin-right: 20px
  }

  .icons img:hover {

    cursor: pointer;
  }

  #fontSize {
    font-size: 16px;
    z-index: 10000000;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }

  /* CSS */
  .button-62 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    background-color: #bb5a3c;
    font-size: 18px;
    padding: 13px;
    width: 200px;
    color: black;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    transition: 0.3s ease;
    border-radius: 7px;
    border: none;
    font-weight: 500;
  }

  .button-62:hover {
    color: rgb(219, 219, 219);
  }

  .logo2 img {
    width: 110px;
    height: 110px;
  }

  .logo2 {}

  .downImg {
    width: 35px;
    -webkit-animation: arrow_down_animation 2s ease-in-out infinite;
            animation: arrow_down_animation 2s ease-in-out infinite;
    cursor: pointer;
  }


  @-webkit-keyframes arrow_down_animation {

    0% {
      -webkit-transform: translate3d(0, 0, 0) scale(1, 1);
              transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      -webkit-transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
              transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;

    }

  }


  @keyframes arrow_down_animation {

    0% {
      -webkit-transform: translate3d(0, 0, 0) scale(1, 1);
              transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      -webkit-transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
              transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;

    }

  }

  .downImgDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -25px;

  }

  .down {
    text-align: center;
    background-color: rgba(0, 0, 0, 0);
  }

  .stickyBtn2 {
    width: 50px;
    height: 50px;
    opacity: 0.5;
    transition: 0.5s ease;
    z-index: 1;
  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
    margin-left: 90%;
    margin-top: -5%;
    cursor: pointer;

    z-index: 1;

  }

  .stickyBtn2:hover {

    opacity: 100%;
    z-index: 1;

  }

  .clouds2 {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .cloud {
    position: absolute;
    top: 0;

  }

  .cloud1 {
    left: 10vw;

  }

  .cloud1 img {
    width: 120%;
    margin-top: -13%;
  }

  .cloud2 img {
    width: 70%;
    margin-top: -8%;
  }

  .cloud3 img {
    width: 100%;

  }

  .cloud1-1 {
    left: 70vw;
    -webkit-animation-name: move-clouds;
            animation-name: move-clouds;
    -webkit-animation-duration: 600s;
            animation-duration: 600s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
  }

  .cloud2 {
    left: 5px;
    -webkit-animation-name: move-clouds;
            animation-name: move-clouds;
    -webkit-animation-duration: 200s;
            animation-duration: 200s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
  }

  .cloud3 {
    left: 3px;

  }

  .cloud3-3 {
    left: 10px;
    -webkit-animation-name: move-clouds;
            animation-name: move-clouds;
    -webkit-animation-duration: 480s;
            animation-duration: 480s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
  }

  .cloud4 {
    left: 10vw;
  }

  .cloud4 img {
    width: 70%;
    margin-top: -5%;
    margin-left: -5%;
  }

  .cloud3 img {
    width: 25%;
  }

  @-webkit-keyframes move-clouds {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(110vw);
              transform: translateX(110vw);
    }
  }

  @keyframes move-clouds {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(110vw);
              transform: translateX(110vw);
    }
  }

  .cloudsLine img {
    width: 100%;
    display: flex;
  }

  .Q {
    font-size: 30px;
    color: white;
    font-weight: 500;
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ecc46d;
  }

  .rmCon {
    color: white;
    font-size: 18px;
  }

  .rmConAndImg {
    margin-bottom: 30px;
    margin-top: 30px;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .rmConMain {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .rmCon img {
    width: 30px;
    height: 30px;
  }

  .pillar1 {
    width: 10.3%;
  }

  .pillar2 {
    width: 10.3%;
  }

  .pillars {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    margin-top: -40%;
  }

  .line {
    width: 100%;
  }

  .walletBtn {
    background-color: #000000;
    border: none;
    font-size: 16px;
    color: white;
    padding: 20px;
    width: 220px;
    transition: 0.3s ease;
  }

  .walletBtn:hover {
    background-color: #3b3b3b;
  }

  .connect2-2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px #7f8082 solid;
    z-index: 10000000;

  }

  .parrot {
    width: 70%;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: black;
    letter-spacing: 1px;
    margin-top: 1%;
  }

  .price {
    text-align: center;
    font-size: 25px;
    color: rgb(0, 0, 0);
    margin-top: 4%;
    display: flex;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .maticP {
    font-size: 18px;
    margin-left: 15px;
    color: rgba(0, 0, 0, 0.774);
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }


  .center {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;

    cursor: pointer;
    background-color: #6cfc1900;
    color: black;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: 1px solid rgba(0, 0, 0, 0.301);
    z-index: 10000000;

  }

  .btnfos-0-2:hover:active {
    background-color: #bbbbbb;
  }

  .btnfos-0-2-2:hover:active {
    background-color: #bbbbbb;
  }

  .btnfos-0-3 {
    margin-top: 20px;
    background-color: rgb(0, 0, 0);
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    padding-left: 30px;
    padding-right: 30px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    border: none;
    z-index: 10000000;
    transition: 0.3s ease;
  }



  .nftamount {
    color: #2b2b2b;
    font-size: 85px;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;

  }

  .successfully {
    text-align: center;
    margin-top: 3%;
    color: #000a42;
    font-size: 30px;
  }

  .errorMessage {
    margin-top: 3%;
    font-size: 20px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(43, 43, 43);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(43, 43, 43);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
    font-size: 35px;
  }

  @-webkit-keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(29, 29, 29);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(29, 29, 29),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(29, 29, 29);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(29, 29, 29),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }


  /*.............. FAQ ..............*/


  summary {
    font-size: 18px;
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border: #0000003a 1px solid;
    color: rgb(0, 0, 0);
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }

  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: rgb(0, 0, 0);
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {
    color: rgb(0, 0, 0);
    padding-bottom: 20px;
    text-align: left;
    font-size: 18px;

  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  }

  .images2 {
    display: flex;
    justify-content: space-evenly;
  }

  .images2 img {
    width: 40%;
    height: 40%;

  }

  .images2 img:hover {}
}

@media screen and (min-width: 1920px) {
  .vidCont6 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1%;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border: 4px solid black;
  }

  .vidCont5 {
    display: none;
  }

  .vidCont1 {
    display: none;
  }

  .vidCont2 {
    display: none;
  }

  .vidCont3 {
    display: none;
  }

  .vidCont4 {
    display: none;
  }
  .socialCreator2 img {
    width: 37px;
    height: 37px;
    margin-left: 10%;
    margin-right: 10%;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .insta {
    width: 45px;
    height: 45px;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    margin-right: 3%;
  }

  .insta:hover {

    cursor: pointer;
  }

  .cloudsMob {
    display: none;
  }


  .connectMob {
    display: none;
  }

  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0);
  }

  .littleNav {
    display: none;
  }

  .miniBarMain {
    display: none;
  }

  .logosMob {
    display: none;
  }

  .allWrap {
    background-color: rgb(255, 255, 255);
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    display: flex;
    flex-flow: column nowrap;
    
  }

  .cont {
    background-color: #000000;
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
  }

  .in2 p {
    font-size: 28px;
    text-align: center;
    font-family: "Iomanoid";
    letter-spacing: 4px;
    text-shadow: 0 0 10px #ffdc64;
    color: #ffdc64;

  }

  .in2 img {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .video {
    width: 70%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: -20px;
  }

  .twitter img {
    width: 50px;
    height: 50px;
    margin-left: 30px;
    cursor: pointer;
    opacity: 0.6;
    transition: 0.2s ease;
    margin-top: -3%;
    z-index: 200000000000;

  }

  .twitter {
    z-index: 200000000000;
  }

  .twitter img:hover {
    opacity: 100%;
    z-index: 200000000000;

  }

  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-size: cover;
    padding-top: 5%;
    padding-bottom: 5%;
  }

  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-size: cover;
    padding-top: 100px;
    padding-bottom: 50px;
  }

  .boxWrap2Sub {
    justify-content: space-between;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }

  .storyH {
    font-size: 70px;
    color: black;
    text-align: center;
    margin-bottom: 50px;
  }

  .storyMain {
    font-size: 70px;
    color: rgb(255, 255, 255);
    text-align: center;
    margin-bottom: 50px;
    margin-top: 25px;
  }

  .about {
    color: rgb(0, 0, 0);
    text-align: left;
    display: flex;
    justify-content: space-evenly;
  }

  .about2 {
    color: rgb(0, 0, 0);
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: center;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  .aboutP {
    width: 35%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .aboutP p {
    font-size: 24px;
    line-height: 55px;
    letter-spacing: 1px;
  }

  .aboutP2 {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .aboutP2 p {
    font-size: 24px;
    line-height: 55px;
    letter-spacing: 1px;
    color: rgb(0, 0, 0);
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.048);
    background-color: #0000001f;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    margin-top: 10px;
    padding-bottom: 10px;
  }

  .aboutP3 {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .aboutP3 p {
    font-size: 24px;
    line-height: 55px;
    letter-spacing: 1px;
    color: rgb(0, 0, 0);
  }

  .aboutP3 div {
    font-size: 24px;
    line-height: 55px;
    letter-spacing: 1px;
    color: rgb(0, 0, 0);
    width: 55%;
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .line {
    width: 100%;
    height: 2px;
    background-color: #c0bcbc;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  }

  .pic {
    width: 55%;
    height: 55%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .logos {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
  }

  .logos_mobile {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
    display: none;
  }

  .logoF {
    text-align: center;
    margin-bottom: 10px;
    color: rgb(0, 0, 0);
    font-size: 30px
  }

  .copyright {
    color: rgb(0, 0, 0);
    text-align: center;
    font-size: 22px;
  }

  .footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0);
    padding-top: 5%;
    padding-bottom: 5%;
    width: 100%;
  }

  .devs img:hover {
    width: 30px;
    height: 30px;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #ffffff;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 100%;


  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Barlow Condensed', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    color: rgb(0, 0, 0);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.3s ease;
  }

  .connect div:hover {
    color: rgb(146, 146, 146);
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-left: auto;
  }

  .icons {
    width: 45px;
    height: 45px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    z-index: 10000000;
    margin-right: 20px
  }

  .icons img:hover {

    cursor: pointer;
  }

  #fontSize {
    font-size: 23px;
    z-index: 10000000;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }

  /* CSS */
  .button-62 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    background-color: #bb5a3c;
    font-size: 18px;
    padding: 13px;
    width: 200px;
    color: black;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    transition: 0.3s ease;
    border-radius: 7px;
    border: none;
    font-weight: 500;
  }

  .button-62:hover {
    color: rgb(219, 219, 219);
  }

  .logo2 img {
    width: 160px;
    height: 160px;
  }

  .logo2 {}

  .downImg {
    width: 35px;
    -webkit-animation: arrow_down_animation 2s ease-in-out infinite;
            animation: arrow_down_animation 2s ease-in-out infinite;
    cursor: pointer;
  }


  @-webkit-keyframes arrow_down_animation {

    0% {
      -webkit-transform: translate3d(0, 0, 0) scale(1, 1);
              transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      -webkit-transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
              transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;

    }

  }


  @keyframes arrow_down_animation {

    0% {
      -webkit-transform: translate3d(0, 0, 0) scale(1, 1);
              transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      -webkit-transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
              transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;

    }

  }

  .downImgDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -25px;

  }

  .down {
    text-align: center;
    background-color: rgba(0, 0, 0, 0);
  }

  .stickyBtn2 {
    width: 65px;
    height: 65px;
    opacity: 0.5;
    transition: 0.5s ease;
    z-index: 1;
  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
    margin-left: 90%;
    margin-top: -5%;
    cursor: pointer;

    z-index: 1;

  }

  .stickyBtn2:hover {

    opacity: 100%;
    z-index: 1;

  }

  .clouds2 {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .cloud {
    position: absolute;
    top: 0;

  }

  .cloud1 {
    left: 10vw;

  }

  .cloud1 img {
    width: 120%;
    margin-top: -13%;
  }

  .cloud2 img {
    width: 70%;
    margin-top: -8%;
  }

  .cloud3 img {
    width: 100%;

  }

  .cloud1-1 {
    left: 70vw;
    -webkit-animation-name: move-clouds;
            animation-name: move-clouds;
    -webkit-animation-duration: 600s;
            animation-duration: 600s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
  }

  .cloud2 {
    left: 5px;
    -webkit-animation-name: move-clouds;
            animation-name: move-clouds;
    -webkit-animation-duration: 200s;
            animation-duration: 200s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
  }

  .cloud3 {
    left: 3px;

  }

  .cloud3-3 {
    left: 10px;
    -webkit-animation-name: move-clouds;
            animation-name: move-clouds;
    -webkit-animation-duration: 480s;
            animation-duration: 480s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
  }

  .cloud4 {
    left: 10vw;
  }

  .cloud4 img {
    width: 70%;
    margin-top: -5%;
    margin-left: -5%;
  }

  .cloud3 img {
    width: 25%;
  }

  @-webkit-keyframes move-clouds {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(110vw);
              transform: translateX(110vw);
    }
  }

  @keyframes move-clouds {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(110vw);
              transform: translateX(110vw);
    }
  }

  .cloudsLine img {
    width: 100%;
    display: flex;
  }

  .Q {
    font-size: 30px;
    color: white;
    font-weight: 500;
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ecc46d;
  }

  .rmCon {
    color: white;
    font-size: 18px;
  }

  .rmConAndImg {
    margin-bottom: 30px;
    margin-top: 30px;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .rmConMain {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .rmCon img {
    width: 30px;
    height: 30px;
  }

  .pillar1 {
    width: 10.3%;
  }

  .pillar2 {
    width: 10.3%;
  }

  .pillars {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    margin-top: -40%;
  }

  .line {
    width: 100%;
  }

  .walletBtn {
    background-color: #000000;
    border: none;
    font-size: 23px;
    color: white;
    padding: 30px;
    width: 290px;
    transition: 0.3s ease;
  }

  .walletBtn:hover {
    background-color: #3b3b3b;
  }

  .connect2-2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px #7f8082 solid;
    z-index: 10000000;

  }

  .parrot {
    width: 70%;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 85px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: black;
    letter-spacing: 1px;
    margin-top: 1%;
  }

  .price {
    text-align: center;
    font-size: 37px;
    color: rgb(0, 0, 0);
    margin-top: 4%;
    display: flex;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .maticP {
    font-size: 25px;
    margin-left: 15px;
    color: rgba(0, 0, 0, 0.774);
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }


  .center {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 30px;
    margin-right: 30px;
    padding: 5px;
    width: 100px;
    height: 100px;
    border: none;

    cursor: pointer;
    background-color: #6cfc1900;
    color: black;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: 1px solid rgba(0, 0, 0, 0.301);
    z-index: 10000000;

  }

  .btnfos-0-2:hover:active {
    background-color: #bbbbbb;
  }

  .btnfos-0-2-2:hover:active {
    background-color: #bbbbbb;
  }

  .btnfos-0-3 {
    margin-top: 20px;
    background-color: rgb(0, 0, 0);
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 45px;
    padding-left: 40px;
    padding-right: 40px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    border: none;
    z-index: 10000000;
    transition: 0.3s ease;
  }



  .nftamount {
    color: #2b2b2b;
    font-size: 125px;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;

  }

  .successfully {
    text-align: center;
    margin-top: 3%;
    color: #000a42;
    font-size: 40px;
  }

  .errorMessage {
    margin-top: 3%;
    font-size: 32px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
  }

  .loadingText {
    font-size: 30px;
    text-align: center;
    color: rgb(43, 43, 43);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(43, 43, 43);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
    font-size: 50px;

  }

  @-webkit-keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(29, 29, 29);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(29, 29, 29),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(29, 29, 29);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(29, 29, 29),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }


  /*.............. FAQ ..............*/


  summary {
    font-size: 22px;
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border: #0000003a 1px solid;
    color: rgb(0, 0, 0);
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }

  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: rgb(0, 0, 0);
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {
    color: rgb(0, 0, 0);
    padding-bottom: 20px;
    text-align: left;
    font-size: 24px;

  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  }

  .images2 {
    display: flex;
    justify-content: space-evenly;
  }

  .images2 img {
    width: 40%;
    height: 40%;

  }

  .images2 img:hover {}
}

@media screen and (min-width: 1921px) and (max-width: 3840px) {
  .vidCont6 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1%;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border: 4px solid black;
  }

  .vidCont5 {
    display: none;
  }

  .vidCont1 {
    display: none;
  }

  .vidCont2 {
    display: none;
  }

  .vidCont3 {
    display: none;
  }

  .vidCont4 {
    display: none;
  }
  .socialCreator2 img {
    width: 45px;
    height: 45px;
    margin-left: 10%;
    margin-right: 10%;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .insta {
    width: 60px;
    height: 60px;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    margin-right: 3%;
  }

  .insta:hover {

    cursor: pointer;
  }

  .cloudsMob {
    display: none;
  }


  .connectMob {
    display: none;
  }

  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0);
  }

  .littleNav {
    display: none;
  }

  .miniBarMain {
    display: none;
  }

  .logosMob {
    display: none;
  }

  .allWrap {
    background-color: rgb(255, 255, 255);
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    display: flex;
    flex-flow: column nowrap;
    
  }

  .cont {
    background-color: #000000;
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
  }

  .in2 p {
    font-size: 28px;
    text-align: center;
    font-family: "Iomanoid";
    letter-spacing: 4px;
    text-shadow: 0 0 10px #ffdc64;
    color: #ffdc64;

  }

  .in2 img {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .video {
    width: 70%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: -20px;
  }

  .twitter img {
    width: 50px;
    height: 50px;
    margin-left: 30px;
    cursor: pointer;
    opacity: 0.6;
    transition: 0.2s ease;
    margin-top: -3%;
    z-index: 200000000000;

  }

  .twitter {
    z-index: 200000000000;
  }

  .twitter img:hover {
    opacity: 100%;
    z-index: 200000000000;

  }

  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-size: cover;
    padding-top: 5%;
    padding-bottom: 5%;
  }

  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-size: cover;
    padding-top: 100px;
    padding-bottom: 50px;
  }

  .boxWrap2Sub {
    justify-content: space-between;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }

  .storyH {
    font-size: 90px;
    color: black;
    text-align: center;
    margin-bottom: 80px;
  }

  .storyMain {
    font-size: 90px;
    color: rgb(255, 255, 255);
    text-align: center;
    margin-bottom: 50px;
    margin-top: 25px;
  }

  .about {
    color: rgb(0, 0, 0);
    text-align: left;
    display: flex;
    justify-content: space-evenly;
  }

  .about2 {
    color: rgb(0, 0, 0);
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: center;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  .aboutP {
    width: 35%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .aboutP p {
    font-size: 32px;
    line-height: 70px;
    letter-spacing: 1px;
  }

  .aboutP2 {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .aboutP2 p {
    font-size: 32px;
    line-height: 70px;
    letter-spacing: 1px;
    color: rgb(0, 0, 0);
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.048);
    background-color: #0000001f;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    margin-top: 10px;
    padding-bottom: 10px;
  }

  .aboutP3 {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .aboutP3 p {
    font-size: 32px;
    line-height: 70px;
    letter-spacing: 1px;
    color: rgb(0, 0, 0);
  }

  .aboutP3 div {
    font-size: 32px;
    line-height: 70px;
    letter-spacing: 1px;
    color: rgb(0, 0, 0);
    width: 55%;
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .line {
    width: 100%;
    height: 2px;
    background-color: #c0bcbc;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  }

  .pic {
    width: 55%;
    height: 55%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .logos {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
  }

  .logos_mobile {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
    display: none;
  }

  .logoF {
    text-align: center;
    margin-bottom: 20px;
    color: rgb(0, 0, 0);
    font-size: 40px
  }

  .copyright {
    color: rgb(0, 0, 0);
    text-align: center;
    font-size: 33px;
  }

  .footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0);
    padding-top: 5%;
    padding-bottom: 5%;
    width: 100%;
  }

  .devs img:hover {
    width: 30px;
    height: 30px;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #ffffff;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 100%;


  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Barlow Condensed', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
  }

  .connect div {
    margin-left: 20px;
    margin-right: 20px;
    color: rgb(0, 0, 0);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.3s ease;
  }

  .connect div:hover {
    color: rgb(146, 146, 146);
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-left: auto;
  }

  .icons {
    width: 65px;
    height: 65px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    z-index: 10000000;
    margin-right: 40px
  }

  .icons img:hover {

    cursor: pointer;
  }

  #fontSize {
    font-size: 32px;
    z-index: 10000000;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }

  /* CSS */
  .button-62 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    background-color: #bb5a3c;
    font-size: 18px;
    padding: 13px;
    width: 200px;
    color: black;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    transition: 0.3s ease;
    border-radius: 7px;
    border: none;
    font-weight: 500;
  }

  .button-62:hover {
    color: rgb(219, 219, 219);
  }

  .logo2 img {
    width: 190px;
    height: 190px;
  }

  .logo2 {}

  .downImg {
    width: 35px;
    -webkit-animation: arrow_down_animation 2s ease-in-out infinite;
            animation: arrow_down_animation 2s ease-in-out infinite;
    cursor: pointer;
  }


  @-webkit-keyframes arrow_down_animation {

    0% {
      -webkit-transform: translate3d(0, 0, 0) scale(1, 1);
              transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      -webkit-transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
              transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;

    }

  }


  @keyframes arrow_down_animation {

    0% {
      -webkit-transform: translate3d(0, 0, 0) scale(1, 1);
              transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      -webkit-transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
              transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;

    }

  }

  .downImgDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -25px;

  }

  .down {
    text-align: center;
    background-color: rgba(0, 0, 0, 0);
  }

  .stickyBtn2 {
    width: 65px;
    height: 65px;
    opacity: 0.5;
    transition: 0.5s ease;
    z-index: 1;
  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
    margin-left: 90%;
    margin-top: -5%;
    cursor: pointer;

    z-index: 1;

  }

  .stickyBtn2:hover {

    opacity: 100%;
    z-index: 1;

  }

  .clouds2 {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .cloud {
    position: absolute;
    top: 0;

  }

  .cloud1 {
    left: 10vw;

  }

  .cloud1 img {
    width: 120%;
    margin-top: -13%;
  }

  .cloud2 img {
    width: 70%;
    margin-top: -8%;
  }

  .cloud3 img {
    width: 100%;

  }

  .cloud1-1 {
    left: 70vw;
    -webkit-animation-name: move-clouds;
            animation-name: move-clouds;
    -webkit-animation-duration: 600s;
            animation-duration: 600s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
  }

  .cloud2 {
    left: 5px;
    -webkit-animation-name: move-clouds;
            animation-name: move-clouds;
    -webkit-animation-duration: 200s;
            animation-duration: 200s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
  }

  .cloud3 {
    left: 3px;

  }

  .cloud3-3 {
    left: 10px;
    -webkit-animation-name: move-clouds;
            animation-name: move-clouds;
    -webkit-animation-duration: 480s;
            animation-duration: 480s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
  }

  .cloud4 {
    left: 10vw;
  }

  .cloud4 img {
    width: 70%;
    margin-top: -5%;
    margin-left: -5%;
  }

  .cloud3 img {
    width: 25%;
  }

  @-webkit-keyframes move-clouds {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(110vw);
              transform: translateX(110vw);
    }
  }

  @keyframes move-clouds {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(110vw);
              transform: translateX(110vw);
    }
  }

  .cloudsLine img {
    width: 100%;
    display: flex;
  }

  .Q {
    font-size: 30px;
    color: white;
    font-weight: 500;
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ecc46d;
  }

  .rmCon {
    color: white;
    font-size: 18px;
  }

  .rmConAndImg {
    margin-bottom: 30px;
    margin-top: 30px;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .rmConMain {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .rmCon img {
    width: 30px;
    height: 30px;
  }

  .pillar1 {
    width: 10.3%;
  }

  .pillar2 {
    width: 10.3%;
  }

  .pillars {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    margin-top: -40%;
  }

  .line {
    width: 100%;
  }

  .walletBtn {
    background-color: #000000;
    border: none;
    font-size: 32px;
    color: white;
    padding: 40px;
    width: 400px;
    transition: 0.3s ease;
  }

  .walletBtn:hover {
    background-color: #3b3b3b;
  }

  .connect2-2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px #7f8082 solid;
    z-index: 10000000;

  }

  .parrot {
    width: 70%;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 115px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: black;
    letter-spacing: 1px;
    margin-top: 1%;
  }

  .price {
    text-align: center;
    font-size: 48px;
    color: rgb(0, 0, 0);
    margin-top: 4%;
    display: flex;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .maticP {
    font-size: 35px;
    margin-left: 18px;
    color: rgba(0, 0, 0, 0.774);
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }


  .center {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 40px;
    margin-right: 40px;
    padding: 5px;
    width: 120px;
    height: 120px;
    border: none;

    cursor: pointer;
    background-color: #6cfc1900;
    color: black;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: 1px solid rgba(0, 0, 0, 0.301);
    z-index: 10000000;

  }

  .btnfos-0-2:hover:active {
    background-color: #bbbbbb;
  }

  .btnfos-0-2-2:hover:active {
    background-color: #bbbbbb;
  }

  .btnfos-0-3 {
    margin-top: 20px;
    background-color: rgb(0, 0, 0);
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 60px;
    padding-left: 50px;
    padding-right: 50px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    border: none;
    z-index: 10000000;
    transition: 0.3s ease;
  }



  .nftamount {
    color: #2b2b2b;
    font-size: 145px;

  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;

  }

  .successfully {
    text-align: center;
    margin-top: 3%;
    color: #000a42;
    font-size: 57px;
  }

  .errorMessage {
    margin-top: 3%;
    font-size: 40px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
  }

  .loadingText {
    font-size: 40px;
    text-align: center;
    color: rgb(43, 43, 43);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(43, 43, 43);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
    font-size: 50px;

  }

  @-webkit-keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(29, 29, 29);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(29, 29, 29),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(29, 29, 29);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(29, 29, 29),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }


  /*.............. FAQ ..............*/


  summary {
    font-size: 32px;
    padding: 2rem;
    margin-bottom: 2rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border: #0000003a 1px solid;
    color: rgb(0, 0, 0);
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }

  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: rgb(0, 0, 0);
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {
    color: rgb(0, 0, 0);
    padding-bottom: 20px;
    text-align: left;
    font-size: 34px;

  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  }

  .images2 {
    display: flex;
    justify-content: space-evenly;
  }

  .images2 img {
    width: 40%;
    height: 40%;

  }

  .images2 img:hover {}
}

@media screen and (min-width: 3840px) {
  .vidCont6 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1%;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border: 4px solid black;
  }

  .vidCont5 {
    display: none;
  }

  .vidCont1 {
    display: none;
  }

  .vidCont2 {
    display: none;
  }

  .vidCont3 {
    display: none;
  }

  .vidCont4 {
    display: none;
  }
  .socialCreator2 img {
    width: 68px;
    height: 68px;
    margin-left: 10%;
    margin-right: 10%;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .insta {
    width: 85px;
    height: 85px;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    margin-right: 3%;
  }

  .insta:hover {

    cursor: pointer;
  }

  .cloudsMob {
    display: none;
  }


  .connectMob {
    display: none;
  }

  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0);
  }

  .littleNav {
    display: none;
  }

  .miniBarMain {
    display: none;
  }

  .logosMob {
    display: none;
  }

  .allWrap {
    background-color: rgb(255, 255, 255);
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    display: flex;
    flex-flow: column nowrap;
    
  }

  .cont {
    background-color: #000000;
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
  }

  .in2 p {
    font-size: 28px;
    text-align: center;
    font-family: "Iomanoid";
    letter-spacing: 4px;
    text-shadow: 0 0 10px #ffdc64;
    color: #ffdc64;

  }

  .in2 img {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .video {
    width: 70%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: -20px;
  }

  .twitter img {
    width: 50px;
    height: 50px;
    margin-left: 30px;
    cursor: pointer;
    opacity: 0.6;
    transition: 0.2s ease;
    margin-top: -3%;
    z-index: 200000000000;

  }

  .twitter {
    z-index: 200000000000;
  }

  .twitter img:hover {
    opacity: 100%;
    z-index: 200000000000;

  }

  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-size: cover;
    padding-top: 5%;
    padding-bottom: 5%;
  }

  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-size: cover;
    padding-top: 100px;
    padding-bottom: 50px;
  }

  .boxWrap2Sub {
    justify-content: space-between;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }

  .storyH {
    font-size: 130px;
    color: black;
    text-align: center;
    margin-bottom: 80px;
  }

  .storyMain {
    font-size: 130px;
    color: rgb(255, 255, 255);
    text-align: center;
    margin-bottom: 50px;
    margin-top: 25px;
  }

  .about {
    color: rgb(0, 0, 0);
    text-align: left;
    display: flex;
    justify-content: space-evenly;
  }

  .about2 {
    color: rgb(0, 0, 0);
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: center;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  .aboutP {
    width: 35%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .aboutP p {
    font-size: 48px;
    line-height: 120px;
    letter-spacing: 1px;
  }

  .aboutP2 {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .aboutP2 p {
    font-size: 48px;
    line-height: 120px;
    letter-spacing: 1px;
    color: rgb(0, 0, 0);
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.048);
    background-color: #0000001f;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .aboutP3 {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .aboutP3 p {
    font-size: 48px;
    line-height: 120px;
    letter-spacing: 1px;
    color: rgb(0, 0, 0);
  }

  .aboutP3 div {
    font-size: 48px;
    line-height: 120px;
    letter-spacing: 1px;
    color: rgb(0, 0, 0);
    width: 55%;
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .line {
    width: 100%;
    height: 2px;
    background-color: #c0bcbc;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  }

  .pic {
    width: 55%;
    height: 55%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .logos {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
  }

  .logos_mobile {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
    display: none;
  }

  .logoF {
    text-align: center;
    margin-bottom: 20px;
    color: rgb(0, 0, 0);
    font-size: 55px;
  }

  .copyright {
    color: rgb(0, 0, 0);
    text-align: center;
    font-size: 45px;
  }

  .footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0);
    padding-top: 5%;
    padding-bottom: 5%;
    width: 100%;
  }

  .devs img:hover {
    width: 30px;
    height: 30px;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #ffffff;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 100%;


  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Barlow Condensed', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
  }

  .connect div {
    margin-left: 30px;
    margin-right: 30px;
    color: rgb(0, 0, 0);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.3s ease;
  }

  .connect div:hover {
    color: rgb(146, 146, 146);
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-left: auto;
  }

  .icons {
    width: 95px;
    height: 95px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    z-index: 10000000;
    margin-right: 40px
  }

  .icons img:hover {

    cursor: pointer;
  }

  #fontSize {
    font-size: 45px;
    z-index: 10000000;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }

  /* CSS */
  .button-62 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    background-color: #bb5a3c;
    font-size: 18px;
    padding: 13px;
    width: 200px;
    color: black;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    transition: 0.3s ease;
    border-radius: 7px;
    border: none;
    font-weight: 500;
  }

  .button-62:hover {
    color: rgb(219, 219, 219);
  }

  .logo2 img {
    width: 315px;
    height: 315px;
  }

  .logo2 {}

  .downImg {
    width: 35px;
    -webkit-animation: arrow_down_animation 2s ease-in-out infinite;
            animation: arrow_down_animation 2s ease-in-out infinite;
    cursor: pointer;
  }


  @-webkit-keyframes arrow_down_animation {

    0% {
      -webkit-transform: translate3d(0, 0, 0) scale(1, 1);
              transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      -webkit-transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
              transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;

    }

  }


  @keyframes arrow_down_animation {

    0% {
      -webkit-transform: translate3d(0, 0, 0) scale(1, 1);
              transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      -webkit-transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
              transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;

    }

  }

  .downImgDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -25px;

  }

  .down {
    text-align: center;
    background-color: rgba(0, 0, 0, 0);
  }

  .stickyBtn2 {
    width: 120px;
    height: 120px;
    opacity: 0.5;
    transition: 0.5s ease;
    z-index: 1;
  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
    margin-left: 90%;
    margin-top: -5%;
    cursor: pointer;

    z-index: 1;

  }

  .stickyBtn2:hover {

    opacity: 100%;
    z-index: 1;

  }

  .clouds2 {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .cloud {
    position: absolute;
    top: 0;

  }

  .cloud1 {
    left: 10vw;

  }

  .cloud1 img {
    width: 120%;
    margin-top: -13%;
  }

  .cloud2 img {
    width: 70%;
    margin-top: -8%;
  }

  .cloud3 img {
    width: 100%;

  }

  .cloud1-1 {
    left: 70vw;
    -webkit-animation-name: move-clouds;
            animation-name: move-clouds;
    -webkit-animation-duration: 600s;
            animation-duration: 600s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
  }

  .cloud2 {
    left: 5px;
    -webkit-animation-name: move-clouds;
            animation-name: move-clouds;
    -webkit-animation-duration: 200s;
            animation-duration: 200s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
  }

  .cloud3 {
    left: 3px;

  }

  .cloud3-3 {
    left: 10px;
    -webkit-animation-name: move-clouds;
            animation-name: move-clouds;
    -webkit-animation-duration: 480s;
            animation-duration: 480s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
  }

  .cloud4 {
    left: 10vw;
  }

  .cloud4 img {
    width: 70%;
    margin-top: -5%;
    margin-left: -5%;
  }

  .cloud3 img {
    width: 25%;
  }

  @-webkit-keyframes move-clouds {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(110vw);
              transform: translateX(110vw);
    }
  }

  @keyframes move-clouds {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(110vw);
              transform: translateX(110vw);
    }
  }

  .cloudsLine img {
    width: 100%;
    display: flex;
  }

  .Q {
    font-size: 30px;
    color: white;
    font-weight: 500;
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ecc46d;
  }

  .rmCon {
    color: white;
    font-size: 18px;
  }

  .rmConAndImg {
    margin-bottom: 30px;
    margin-top: 30px;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .rmConMain {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .rmCon img {
    width: 30px;
    height: 30px;
  }

  .pillar1 {
    width: 10.3%;
  }

  .pillar2 {
    width: 10.3%;
  }

  .pillars {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    margin-top: -40%;
  }

  .line {
    width: 100%;
  }

  .walletBtn {
    background-color: #000000;
    border: none;
    font-size: 45px;
    color: white;
    padding: 60px;
    width: 530px;
    transition: 0.3s ease;
  }

  .walletBtn:hover {
    background-color: #3b3b3b;
  }

  .connect2-2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px #7f8082 solid;
    z-index: 10000000;

  }

  .parrot {
    width: 70%;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 155px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: black;
    letter-spacing: 1px;
    margin-top: 1%;
  }

  .price {
    text-align: center;
    font-size: 60px;
    color: rgb(0, 0, 0);
    margin-top: 4%;
    display: flex;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .maticP {
    font-size: 48px;
    margin-left: 18px;
    color: rgba(0, 0, 0, 0.774);
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }


  .center {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .btnfos-0-2 {
    font-size: 50px;
    margin-left: 40px;
    margin-right: 40px;
    padding: 5px;
    width: 160px;
    height: 160px;
    border: none;

    cursor: pointer;
    background-color: #6cfc1900;
    color: black;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: 1px solid rgba(0, 0, 0, 0.301);
    z-index: 10000000;

  }

  .btnfos-0-2:hover:active {
    background-color: #bbbbbb;
  }

  .btnfos-0-2-2:hover:active {
    background-color: #bbbbbb;
  }

  .btnfos-0-3 {
    margin-top: 20px;
    background-color: rgb(0, 0, 0);
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 80px;
    padding-left: 70px;
    padding-right: 70px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    border: none;
    z-index: 10000000;
    transition: 0.3s ease;
  }



  .nftamount {
    color: #2b2b2b;
    font-size: 200px;

  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;

  }

  .successfully {
    text-align: center;
    margin-top: 3%;
    color: #000a42;
    font-size: 70px;
  }

  .errorMessage {
    margin-top: 3%;
    font-size: 60px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
  }

  .loadingText {
    font-size: 60px;
    text-align: center;
    color: rgb(43, 43, 43);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(43, 43, 43);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
    font-size: 50px;

  }

  @-webkit-keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(29, 29, 29);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(29, 29, 29),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(29, 29, 29);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(29, 29, 29),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }


  /*.............. FAQ ..............*/


  summary {
    font-size: 45px;
    padding: 3rem;
    margin-bottom: 3rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border: #0000003a 1px solid;
    color: rgb(0, 0, 0);
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }

  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: rgb(0, 0, 0);
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {
    color: rgb(0, 0, 0);
    padding-bottom: 20px;
    text-align: left;
    font-size: 47px;

  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  }

  .images2 {
    display: flex;
    justify-content: space-evenly;
  }

  .images2 img {
    width: 40%;
    height: 40%;

  }

  .images2 img:hover {}
}
